import React, { useState } from "react";
import quickBooksPng from "@/assets/images/quickBooksLogo.png";
import rightArrowPng from "@/assets/images/rightArrow.png";
import Dropdown from "@/components/Dropdown/Dropdown";
import SalesChart from "@/components/Charts/SalesChart";
import DonutChart from "@/components/Charts/DonutChart";
import Heading from "@/components/Heading/Heading";
import HeadingCard from "@/components/Cards/HeadingCard";

const QuickBooks = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState("");

  const timeframe = [
    { value: "7_days", label: "Last 7 Days" },
    { value: "30_days", label: "Last 30 Days" },
    { value: "90_days", label: "Last 90 Days" },
  ];

  const handleTimeframeChange = (value) => {
    setSelectedTimeframe(value);
  };

  return (
    <div className="flex flex-col lg:flex-row font-sf-pro-display">
      <div className="h-[100%] w-[100%] text-primary">
        <h1 className="text-4xl font-DIN-Condensed font-bold mb-5">
          Quick Books
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-5">
          {/* Profit n loss */}
          <HeadingCard src={quickBooksPng} cardTitle={"Profit and Loss"} height={"h-[90%]"}>
            <div className="w-full flex justify-end items-center ">
              <Dropdown
                selectedOption={selectedTimeframe}
                onOptionChange={handleTimeframeChange}
                myArray={timeframe}
                optionLabel="Last 30 Days"
                className={" border-none outline-none text-lg text-primary cursor-pointer"}
              />
            </div>
            <div>
              <p className="text-[28px] text-primary font-bold">+ $15,500</p>
              <p className="text-base">Net Income for last 30 days</p>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[28px] text-primary font-bold">$16,500</p>
                <p className="text-base">Gross Income</p>
              </div>
              <span className="h-2 w-full bg-deepskyblue-100 rounded-full ml-3"></span>{" "}
            </div>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[28px] text-primary font-bold">$16,500</p>
                <p className="text-base">Expenses</p>
              </div>
              <span className="h-2 w-full bg-deepskyblue-100 rounded-full ml-3"></span>{" "}
            </div>
            <button className="flex justify-start items-center">
              {" "}
              <p className="text-sm font-bold">See Profit and Loss Report</p>
              <img src={rightArrowPng} alt="right arrow" className="ml-5" />{" "}
            </button>
          </HeadingCard>

          {/* Expenses */}
          <HeadingCard src={quickBooksPng} cardTitle={"Expenses"} height={"h-[90%]"} >
            <div className="w-full flex justify-end items-center ">
              <Dropdown
                selectedOption={selectedTimeframe}
                onOptionChange={handleTimeframeChange}
                myArray={timeframe}
                optionLabel="Last 30 Days"
                className={" border-none text-lg text-primary"}
              />
            </div>
            <div className="grid grid-cols-2">
              <div className="space-y-5">
                <div>
                  <p className="text-[28px] text-primary font-bold">+ $15,500</p>
                  <p className="text-base">Net Income for last 30 days</p>
                </div>
                <div className="flex justify-start items-center">
                  <span className="p-1.5 bg-deepskyblue-100 rounded-full mr-3"></span>
                  <div>
                    <p className="text-xl text-primary font-bold">$500</p>
                    <p className="text-sm">Lorem ipsum dolor</p>
                  </div>
                </div>
                <div className="flex justify-start items-center">
                  <span className="p-1.5 bg-[#83E5FF] rounded-full mr-3"></span>
                  <div>
                    <p className="text-xl text-primary font-bold">$350</p>
                    <p className="text-sm">Consectetur adipiscing elit</p>
                  </div>
                </div>
                <div className="flex justify-start items-center">
                  <span className="p-1.5 bg-[#D1D1D1] rounded-full mr-3"></span>
                  <div>
                    <p className="text-xl text-primary font-bold">$500</p>
                    <p className="text-sm">Duis mattis lorem</p>
                  </div>
                </div>
              </div>
              <div className="">
                <DonutChart height="100%" width="100%" />
              </div>
            </div>
          </HeadingCard>

          {/* Invoices */}
          <HeadingCard src={quickBooksPng} cardTitle={"Invoices"} height={"h-[90%]"}>
            <div className="space-y-5">
              <span className="text-xl text-primary font-bold">
                $125,500 Unpaid{" "}
              </span>
              <span className="text-lg">Last 365 days</span>
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="text-[28px] text-primary font-bold">$16,500</p>
                    <p className="text-base">Overdue</p>
                  </div>
                  <div>
                    <p className="text-[28px] text-primary font-bold">$0</p>
                    <p className="text-base">Not due yet</p>
                  </div>
                </div>
                <p className="h-2 w-full bg-deepskyblue-100 mt-3 rounded-full ml-3"></p>{" "}
              </div>
            </div>
            <div className="mt-5 space-y-5">
              <span className="text-xl text-primary font-bold">$0 Unpaid </span>
              <span className="text-lg">Last 365 days</span>
              <div className="flex flex-col justify-center items-center">
                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="text-[28px] text-primary font-bold">$0</p>
                    <p className="text-base">Not deposited</p>
                  </div>
                  <div>
                    <p className="text-[28px] text-primary font-bold">$0</p>
                    <p className="text-base">Deposited</p>
                  </div>
                </div>
                <p className="h-2 w-full bg-deepskyblue-100 rounded-full mt-3 ml-3"></p>{" "}
              </div>
            </div>
          </HeadingCard>

          {/* Sales chart */}
          <HeadingCard src={quickBooksPng} cardTitle={"Sales"} height={"h-[90%]"}>
            <div className="w-full flex justify-end items-center ">
              <Dropdown
                selectedOption={selectedTimeframe}
                onOptionChange={handleTimeframeChange}
                myArray={timeframe}
                optionLabel="Last 30 Days"
                className={" border-none text-lg text-primary cursor-pointer"}
              />
            </div>
            {/* <div>
                <p className="text-[28px] text-primary font-bold"> $500</p>
                <p className="text-base">Total Sales</p>
              </div> */}
            <SalesChart height="100%" width="100%" />
          </HeadingCard>
        </div>
      </div>
    </div>
  );
};

export default QuickBooks;
