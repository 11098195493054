import brushImg from "../../assets/images/long-hair-dogs.png";
const NotificationCard = ({ stockLevel = '', className = '', stock = '', sku = "" }) => {
    return (
        <>
            <div className=" flex flex-col w-full   border-b border-b-[#EBEBEB] ">
                <div className="flex flex-col sm:flex-col md:flex-row py-4 ">
                    <div className="flex-1 p-4 border-r border-r-[#EBEBEB]">
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-row flex-basis-[75%] items-center gap-4 font-DIN-Condensed">
                                <img
                                    src={brushImg}
                                    alt="product"
                                    className="h-[100px] w-[100px] p-2 border-[1px] border-[#E6E6E6] bg-[#F6F6F6] rounded-md object-contain"
                                />
                                <p className="text-[20px] w-3/4" > {sku} </p>
                            </div>

                            <div className="flex flex-col  items-center flex-basis-[25%]">
                                <p>In Stock</p>
                                <h1 className="text-[20px] ">{stock}</h1>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 p-4">
                        <div className="flex flex-row justify-between items-center">
                            <div className=" flex-col ">
                                <p>Stock Level</p>
                                <h1 >{stockLevel}!</h1>
                                <p className={`text-4xl mt-[-10px] ${className}`}>__</p>
                            </div>
                            <div>
                                <button className="px-8 py-1 border rounded-[30px] bg-[#F46A6A] text-white truncate font-DIN-Condensed">Alert User</button>
                            </div>

                        </div>
                    </div>

                </div>




            </div>
        </>
    )
}
export default NotificationCard;