import { useLayoutEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import Sidebar from '@/components/Dashboard/Sidebar';
import Navbar from '@/components/Dashboard/Navbar';
import Footer from '@/components/Dashboard/Footer';

export const DashboardLayout = (props) => {
  const { children } = props;
  const mainRef = useRef();
  const [showMenu, setShowMenu] = useState(false);

  const show = () => setShowMenu(!showMenu)

  useLayoutEffect(() => {
    mainRef.current.onclick = (ev) => {
      if (ev.target.name === "hamburger" || ev.target.parentElement.name === 'hamburger' || ev.target.parentElement.parentElement.name === 'hamburger' || ev.target.parentElement.parentElement.parentElement.name === 'hamburger') return;
      if (showMenu) setShowMenu(false)
    }
  }, [])

  return (
    <div className='h-screen flex'>
      <div className={`fixed ${showMenu ? 'max-md:left-0' : 'max-md:-left-72'} transition-all md:relative md:block w-[290px] bg-darkslategray-300 box-border min-h-screen z-40`}>
        <Sidebar show={show} mainRef={mainRef} />
      </div>
      <div ref={mainRef} className='w-full md:w-[calc(100vw-290px)]'>
        <Navbar show={show} />
        <div className='mt-[70px] overflow-y-auto md:h-[calc(100vh-120px)] bg-[#F8F8FB] px-6 pt-5 pb-9 grid'>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};
