import Dropdown from "@/components/Dropdown/Dropdown";
import Heading from "@/components/Heading/Heading";
import RadioButton from "@/components/RadioButton/RadioButton";
import Table from "@/components/Tables/Table";
import React, { useEffect, useState } from "react";
import { get } from "@/ApiRoute/api";
import RealTimeOrder from "./RealTimeOrder";
import { columns, options, ids, recent } from "./Extra";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/AuthContext";
import PaginationComp from "@/components/Pagination/PaginationComp";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderHistory } from "@/redux/apiSlice";
import {
  CURRENT_PAGE_Ques,
  ORDER_HISTORY_API,
  PAGE_SIZE,
} from "@/ApiRoute/apiUrls";

const Index = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [perPageCount, setPerPageCount] = useState(5);

  const navigate = useNavigate();
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleIdChange = (value) => {
    setSelectedId(value);
  };

  useEffect(() => {
    dispatch(
      fetchOrderHistory(
        `${ORDER_HISTORY_API +
        CURRENT_PAGE_Ques +
        currentPage +
        PAGE_SIZE +
        perPageCount
        }`
      )
    );
  }, [currentPage, perPageCount]);


  useEffect(() => {
    dispatch(
      fetchOrderHistory(
        `${ORDER_HISTORY_API +
        CURRENT_PAGE_Ques +
        currentPage +
        PAGE_SIZE +
        perPageCount
        }`
      )
    );
  }, []);

  const orderHistoryData = useSelector(
    (state) => state.myApis.orderHistoryRecord
  );

  useEffect(() => {
    const data = orderHistoryData?.data;
    if (data) {
      setTotalCount(data?.total_count);
      setCurrentPage(data?.search_criteria?.current_page);
    }
  }, [orderHistoryData?.data?.total_count]);

  const handleButton = (val, id) => {
    console.log("ccccccccccc", val, id);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  const handlePaginationDrop = (value) => {
    setPerPageCount(value);
  }

  const getOptions = () => {
    const optionsCount = Array.from({ length: 5 }, (_, index) => ({
      label: (index + 1) * 10,
      value: (index + 1) * 10,
    }));
    return optionsCount
  }

  return (
    <div className="flex flex-col xl:h-[100%] w-[100%] lg:flex-row font-sf-pro-display">
      <div className="h-[100%] w-full">
        <h1 className="text-4xl font-bold font-DIN-Condensed text-primary">
          Order Management
        </h1>
        <div className="text-[28px] font-DIN-Condensed text-primary">
          <RadioButton
            options={options}
            onOptionChange={handleOptionChange}
            className=""
          />
        </div>

        {/* Table */}
        <div className="">
          <div className="flex justify-between items-center">
            <h1 className="text-[28px] font-DIN-Condensed font-bold text-[#434345] my-3">
              Order History
            </h1>
            <div className="flex justify-center items-center">
              <RadioButton
                options={recent}
                onOptionChange={handleOptionChange}
                className=""
              />
              <Dropdown
                selectedOption={selectedId}
                onOptionChange={handleIdChange}
                myArray={ids}
                optionLabel="All Orders"
                className={
                  " border-none bg-[#F5F5F5] cursor-pointer text-base font-medium"
                }
              />
            </div>
          </div>

          {/* table */}
          <div className="w-[100%] mb-3">
            <Table
              columns={columns}
              rows={orderHistoryData?.data?.items}
              largeScreenClass={"hidden xl:block"}
              smallScreenClass={"block xl:hidden"}
              handleButton={handleButton}
            />
          </div>
        </div>

        {/* pagination */}

        <div className="flex flex-col sm:flex-row justify-between w-full items-center sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="flex flex-col xs:flex-row justify-between " >
            <Dropdown
              selectedOption={perPageCount}
              onOptionChange={(e) => handlePaginationDrop(e)}
              myArray={getOptions()}
              optionLabel="Select Order ID"
              className={
                "border-[1px] border-[#C2C2C2] bg-white cursor-pointer rounded-md text-base font-medium"
              }
            />
            <p className="text-xl text-primary ml-4 mt-2">
              Showing <span className="font-bold"> {data?.length} / {totalCount}</span> items per page
            </p>
          </div>
          <PaginationComp handlePageClick={handlePageClick} totalCount={totalCount} perPageCount={perPageCount} />
        </div>

        {/* Real-time status */}
        <RealTimeOrder handleIdChange={handleIdChange} ids={ids} />
      </div>
    </div>
  );
};

export default Index;
