import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    ApolloLink
} from "@apollo/client";
import {errorLink, authLink, httpLink} from "@/lib/apollo/apollo-link";

function makeClient() {
    return new ApolloClient({
        cache: new InMemoryCache(),
        connectToDevTools: true,
        link: ApolloLink.from([errorLink, authLink, httpLink])
    });
}

export function ApolloWrapper({ children }) {
    return (
        <ApolloProvider client={makeClient()}>
            {children}
        </ApolloProvider>
    );
}