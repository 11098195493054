import React, { useEffect, useState } from "react";
import DealerManagementCard from "@/components/Cards/DealerManagementCard";
import Dropdown from "@/components/Dropdown/Dropdown";
import manImg from "@/assets/images/man.png";
import Heading from "@/components/Heading/Heading";
import PaginationComp from "@/components/Pagination/PaginationComp";
import { fetchDealerManagemet } from "@/redux/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SORT_BY_NAME_DESC,
  SORT_BY_NAME_ASC,
  PAGE_SIZE,
  CURRENT_PAGE,
  FILTER_FIELDS_PAGINATION,
  CUSTOMER_SEARCH,
} from "@/ApiRoute/apiUrls";

const SORT = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
};

const DealerManagement = () => {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState("");
  const [dealers, setDealers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [perPageCount, setPerPageCount] = useState(15);

  const handleSorting = (value) => {
    setSorting(value);
    fetchData(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [sorting, currentPage, perPageCount]);

  const fetchData = () => {
    let sort = "";
    if (sorting === SORT.DESCENDING) {
      sort = SORT_BY_NAME_DESC;
    } else if (sorting === SORT.ASCENDING) {
      sort = SORT_BY_NAME_ASC;
    }
    dispatch(
      fetchDealerManagemet(
        `${CUSTOMER_SEARCH +
        PAGE_SIZE +
        perPageCount +
        CURRENT_PAGE +
        currentPage +
        FILTER_FIELDS_PAGINATION +
        sort
        }`
      )
    );
  };

  const dealerManageData = useSelector(
    (state) => state.myApis.dealerManagementRecord
  );
  // console.log("0000000", dealerManageData);

  useEffect(() => {
    const data = dealerManageData?.data;
    if (data) {
      setDealers(data.items);
      setTotalCount(data?.total_count);
      setCurrentPage(data?.search_criteria?.current_page);
    }
  }, [dealerManageData?.data?.total_count]);

  const sortingOrder = [
    { label: "--Filter--", value: "" },
    { label: "Alphabetical A - Z", value: "ASC" },
    { label: "Reverse Alphabetical Z - A", value: "DESC" },
  ];

  const getImg = (data) => {
    let dummy = manImg;
    const img = data?.find((val) => val?.attribute_code == "profile_pic");
    if (![null, undefined, 0].includes(img?.value)) {
      dummy = "https://store2.fur4.com/dealer/customer/file?file=" + img?.value;
    }
    return dummy;
  };

  const handlePageClick = (event) => {
    console.log("event.selected", event.selected);
    setCurrentPage(event.selected + 1);
  };

  const handlePaginationDrop = (value) => {
    setPerPageCount(value);
  }

  const getOptions = () => {
    const optionsCount = Array.from({ length: 5 }, (_, index) => ({
      label: (index + 1) * 10,
      value: (index + 1) * 10,
    }));
    return optionsCount
  }

  return (
    <div className="flex flex-col xl:h-[100%] w-[100%] lg:flex-row font-sf-pro-display">
      <div className="h-[100%] w-full">
        <h1 className="text-4xl font-bold font-DIN-Condensed text-primary mb-5">
          Dealer Management
        </h1>
        <div className="bg-white rounded-md drop-shadow-md  p-5">
          <div className="flex justify-start items-center mb-5">
            <p className=" text-primary text-lg font-bold mr-5">Sorting</p>
            <Dropdown
              selectedOption={sorting}
              onOptionChange={handleSorting}
              myArray={sortingOrder}
              optionLabel="Date "
              className={
                " bg-deepskyblue-100 cursor-pointer border-none text-white rounded-md text-base font-medium"
              }
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 h-[100%]">
            {dealerManageData?.data?.items?.length > 0
              ? dealerManageData?.data?.items.map((dealer) => (
                <DealerManagementCard
                  key={dealer.id}
                  src={getImg(dealer?.custom_attributes)}
                  name={dealer.firstname + " " + dealer.lastname}
                />
              ))
              : dealerManageData?.loading
                ? "...Loading"
                : !dealerManageData?.loading &&
                dealerManageData.data?.length == 0 &&
                "Data not Found"}
          </div>

          {/* Pagination */}

          <div className="flex flex-col sm:flex-row justify-between w-full items-center sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="flex flex-col xs:flex-row justify-between " >
              <Dropdown
                selectedOption={perPageCount}
                onOptionChange={(e) => handlePaginationDrop(e)}
                myArray={getOptions()}
                optionLabel="Select Order ID"
                className={
                  "border-[1px] border-[#C2C2C2] bg-white cursor-pointer rounded-md text-base font-medium"
                }
              />
              <p className="text-xl text-primary ml-4 mt-2">
                Showing <span className="font-bold"> {dealers?.length} / {totalCount}</span> items per page
              </p>
            </div>
            <PaginationComp handlePageClick={handlePageClick} totalCount={totalCount} perPageCount={perPageCount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerManagement;
