import { useLocation } from 'react-router-dom';

export default function Footer() {

  return (
    <div className="bg-[#F2F2F5]">
      <div className="container px-5 py-4 mx-auto">
        <p className="text-[12px] text-[#74788D] capitalize xl:text-center max-lg:text-center flex justify-center items-center max-md:flex-col">
          Ⓡ/TM/©2024 FUR4, LLC
        </p>

      </div>
    </div>
  )
}

