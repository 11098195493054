import React, { useEffect, useState } from "react";
import chimpPng from "@/assets/images/mailchimp.png";
import LineChart from "@/components/Charts/LineChart";
import Table from "@/components/Tables/Table";
import Heading from "@/components/Heading/Heading";
import HeadingCard from "@/components/Cards/HeadingCard";
import Dropdown from "@/components/Dropdown/Dropdown";
import { getMailchimp } from '@/ApiRoute/api'

const MailChimp = () => {
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([
    { order_id: "#000123", order_id: "order_id", name: 'test', product: 'test', qty: "100", date: "2024-08-09" },
    { order_id: "#000124", accessor: "200", name: 'test', product: 'test', qty: "100", date: "2024-08-09" },
    { order_id: "#000125", accessor: "300", name: 'test', product: 'test', qty: "100", date: "2024-08-09" },

  ]);
  const [totalRecipients, setTotalRecipients] = useState(0);

  const columns = [
    { header: "DEALER NAME", accessor: "name" },
    { header: "Order ID", accessor: "order_id" },
    { header: "Product", accessor: "product" },
    { header: "Quantity", accessor: "qty" },
    { header: "Order Date", accessor: "date" },

  ];


  // useEffect(() => {
  //   fetchData();
  //   console.log("----------------");
  // }, []);

  // const fetchData = async () => {
  //   // setLoading(true);
  //   try {
  //     const getData = await getMailchimp(`reports`);
  //     console.log("orderData", getData);
  //     if (getData) {
  //       console.log("getData" , getData);
  //       // setData(orderData[0]);
  //       // setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log("error" , error);
  //     if (error.response) {
  //       // setLoading(false);
  //       if (error.response.status === 401) {
  //         // logout();
  //         // navigate('/login');
  //       } else {
  //         console.log(`Error: ${error.response.status}`);
  //       }
  //     } else {
  //       console.log('An unexpected error occurred');
  //     }
  //   }
  // };

  const months = [
    { label: "January" },
    { label: "February" },
    { label: "March" },
    { label: "April" },
    { label: "May" },
    { label: "June" },
    { label: "July" },
    { label: "August" },
    { label: "September" },
    { label: "October" },
    { label: "November" },
    { label: "December" },
  ];

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 2020; year <= currentYear; year++) {
    years.push({ value: year, label: year });
  }

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  // useEffect(() => {
  //   const fetchOverallRecipients = async () => {
  //     const API_KEY = 'YOUR_API_KEY';
  //     const SERVER_PREFIX = 'usX'; // Replace with your server prefix (e.g., us1, us2, etc.)

  //     const fetchCampaigns = async () => {
  //       const response = await getMailchimp(`campaigns`);
  //       // const response = await axios.get(`https://${SERVER_PREFIX}.api.mailchimp.com/3.0/campaigns`, {
  //       //   headers: {
  //       //     Authorization: `apikey ${API_KEY}`
  //       //   }
  //       // });
  //       return response.campaigns;
  //     };

  //     const fetchCampaignReport = async (campaignId) => {
  //       const response = await getMailchimp(`reports/${campaignId}`);
  //       // const response = await axios.get(`https://${SERVER_PREFIX}.api.mailchimp.com/3.0/reports/${campaignId}`, {
  //       //   headers: {
  //       //     Authorization: `apikey ${API_KEY}`
  //       //   }
  //       // });
  //       return response;
  //     };

  //     const campaigns = await fetchCampaigns();
  //     let totalRecipients = 0;
  //     for (const campaign of campaigns) {
  //       const report = await fetchCampaignReport(campaign.id);
  //       console.log("report", report);
  //       totalRecipients += report.emails_sent;
  //     }

  //     setTotalRecipients(totalRecipients);
  //   };

  //   fetchOverallRecipients();
  // }, []);

  // const data1 = [
  //   ['Metric', 'Value'],
  //   ['Overall Recipients', totalRecipients]
  // ];
  // console.log("setTotalRecipients", totalRecipients, data1);

  return (
    <div className="flex flex-col xl:h-[100%] w-[100%] lg:flex-row font-sf-pro-display relative">
      <div className=" w-[100%]">
        <h1 className="text-4xl font-DIN-Condensed text-left w-full font-bold mb-5 text-[#434345]">
          Mailchimp Campaigns
        </h1>

        {/* Date */}
        <div className="flex flex-col justify-end items-end absolute top-5 right-5">
          <div className="flex justify-end  text-lg text-primary font-bold mb-2">
            <p>{selectedMonth}</p>
            {","}
            <p>{selectedYear}</p>
          </div>
          <div className="flex justify-center">
            <Dropdown
              selectedOption={selectedMonth}
              onOptionChange={handleMonthChange}
              myArray={months}
              optionLabel="Select Month"
              className={
                "drop-shadow-md bg-white text-deepskyblue-100 rounded-l-md text-base font-medium"
              }
            />
            <Dropdown
              selectedOption={selectedYear}
              onOptionChange={handleYearChange}
              myArray={years}
              optionLabel="Select Year"
              className={
                "drop-shadow-md bg-white text-deepskyblue-100 rounded-r-md text-base font-medium"
              }
            />
          </div>
        </div>
        <div className="flex flex-col mt-16">
          {/* chart row */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {/* Reciepients */}
            <HeadingCard src={chimpPng} cardTitle={"Recipients"}>
              <LineChart height="100%" width="100%" />
            </HeadingCard>

            {/* Opens */}
            <HeadingCard src={chimpPng} cardTitle={"Opens"}>
              <LineChart height="100%" width="100%" />
            </HeadingCard>

            {/* Clicks */}
            <HeadingCard src={chimpPng} cardTitle={"Clicks"}>
              <LineChart height="100%" width="100%" />
            </HeadingCard>
          </div>

          {/* card row */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2 my-5">
            <div className="flex flex-col justify-start items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-around items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed font-bold text-primary">
                  Recipients
                </h1>
              </div>
              <p className="text-5xl  xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                $12,000
              </p>
            </div>
            <div className="flex flex-col justify-around items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-start items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed  font-bold text-primary">
                  Average Open Rate
                </h1>
              </div>
              <p className="text-5xl xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                19,800
              </p>
            </div>
            <div className="flex flex-col justify-around items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-start items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed font-bold text-primary">
                  Average Unsubscriber Rate
                </h1>
              </div>
              <p className="text-5xl xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                3125
              </p>
            </div>
            <div className="flex flex-col justify-around items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-start items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed font-bold text-primary">
                  Average Bounce Rate
                </h1>
              </div>
              <p className="text-5xl xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                11,040
              </p>
            </div>
            <div className="flex flex-col justify-around items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-start items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed font-bold text-primary">
                  Deliveries
                </h1>
              </div>
              <p className="text-5xl xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                19,800
              </p>
            </div>
            <div className="flex flex-col justify-around items-center p-5 bg-white rounded-md drop-shadow-md">
              <div className="flex justify-start items-start mb-5">
                <img src={chimpPng} alt="chimp icon " className="mr-2" />
                <h1 className="text-xl font-DIN-Condensed font-bold text-primary">
                  Average Spam Rate
                </h1>
              </div>
              <p className="text-5xl xl:text-3xl 2xl:text-5xl text-center text-deepskyblue-100">
                8.25%
              </p>
            </div>
          </div>

          {/* Campaign performance table */}
          <div className="">
            <h1 className="text-[28px] font-DIN-Condensed font-bold text-[#434345] mt-3">
              Campaign Results
            </h1>
            <div className="w-[100%] mt-2">
              <Table
                columns={columns}
                rows={data}
                largeScreenClass={"hidden md:block"}
                smallScreenClass={"block md:hidden"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailChimp;
