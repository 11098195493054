import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import ProtectedRoute from './PrivateRoute';
import AdminDashboard from "@/pages/AdminDashboard";
import GoogleAnalytics from "@/pages/GoogleAnalytics";
import QuickBooks from "./pages/QuickBooks";
import MailChimp from "./pages/MailChimp";
import SupportSystem from "./pages/SupportSystem";
import OrderManagement from "./pages/OrderManagement/index";
import DealerApprovals from "./pages/DealerApprovals";
import DealerManagement from "./pages/DealerManagement";
import CustomReports from "./pages/CustomReports";
import SalesTrends from "./pages/SalesTrends";
import DashboardCustomization from "./pages/DashboardCustomization";
import NotificationAlerts from "./pages/NotificationAlert";
import SupportAccess from "./pages/SupportAccess";
import UserGuide from "./pages/UserGuide";
import Login from './pages/Login';
import { useAuth } from './AuthContext'
import { DashboardLayout } from './components/Layouts/DashboardLayout';

const Routes = () => {
  const { auth } = useAuth();

  const routes = useRoutes([
    { path: '/login', element: auth ? <Navigate to="/" /> : <Login /> },
    { path: '/admin/home', element: (<ProtectedRoute> <DashboardLayout><AdminDashboard /> </DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/analytics', element: (<ProtectedRoute> <DashboardLayout><GoogleAnalytics /></DashboardLayout>  </ProtectedRoute>) },
    { path: '/admin/quickBooks', element: (<ProtectedRoute><DashboardLayout> <QuickBooks /></DashboardLayout>  </ProtectedRoute>) },
    { path: '/admin/mailchimp', element: (<ProtectedRoute><DashboardLayout> <MailChimp /></DashboardLayout></ProtectedRoute>) },
    { path: '/admin/supportSystem', element: (<ProtectedRoute><DashboardLayout> <SupportSystem /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/orderManagement', element: (<ProtectedRoute><DashboardLayout> <OrderManagement /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/dealerApprovals', element: (<ProtectedRoute><DashboardLayout> <DealerApprovals /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/dealerManagement', element: (<ProtectedRoute><DashboardLayout> <DealerManagement /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/customReports', element: (<ProtectedRoute><DashboardLayout> <CustomReports /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/sales', element: (<ProtectedRoute><DashboardLayout> <SalesTrends /> </DashboardLayout></ProtectedRoute>) },
    { path: '/admin/customize', element: (<ProtectedRoute><DashboardLayout> <DashboardCustomization /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/notifications', element: (<ProtectedRoute> <DashboardLayout><NotificationAlerts /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/supportAccess', element: (<ProtectedRoute><DashboardLayout> <SupportAccess /></DashboardLayout> </ProtectedRoute>) },
    { path: '/admin/guide', element: (<ProtectedRoute><DashboardLayout> <UserGuide /></DashboardLayout> </ProtectedRoute>) },
    { path: '/', element: <Navigate to="/admin/home" /> },
  ]);
  return routes;
};

export default Routes;