import { useState } from "react";
import Dropdown from "@/components/Dropdown/Dropdown";

export default function UserGuide() {
  const [selected, setSelected] = useState("");

  const data = [
    { value: "ga", label: "Google Analytics & Google Ads" },
    { value: "ga", label: "Google Analytics & Google Ads" },
    { value: "ga", label: "Google Analytics & Google Ads" },
  ];

  const handleChange = (value) => {
    setSelected(value);
  };

  return (
    <div className="text-primary">
      <h1 className="text-4xl font-DIN-Condensed font-bold mb-5">
        User Guide
      </h1>
      <h2 className="text-[32px] font-bold font-DIN-Condensed">How to use each part of the admin panel ...</h2>
      <div className="bg-white w-full rounded-md shadow-[0px_4px_8px_0px_rgba(0,0,0,0.04)] pl-6 py-6 pr-14 overflow-auto h-[calc(100vh-290px)]">
        <Dropdown
          selectedOption={selected}
          onOptionChange={handleChange}
          myArray={data}
          optionLabel="Google Analytics & Google Ads"
          className={"border-none text-lg text-primary cursor-pointer pl-0 focus:ring-0 font-semibold"}
        />
        <text className="text-base">
          Lorem ipsum dolor sit amet consectetur. Libero elit amet nunc blandit morbi. Ante nibh tortor consectetur quis ornare. Nec justo metus diam vel neque massa. Fusce convallis convallis feugiat hendrerit laoreet neque molestie arcu aliquam. Sit ultricies in ac habitant pretium amet ultrices id. Pharetra et neque ornare eget. Placerat a blandit scelerisque arcu. Sed pretium tempus nec turpis interdum eget. Orci urna purus quis ut ullamcorper tellus vehicula venenatis eleifend. Urna venenatis sit pellentesque ac pharetra auctor tortor dictum turpis. Porta sed arcu quis facilisis. Condimentum duis pharetra auctor neque eget imperdiet ultrices. Vivamus habitasse molestie.
          <br /><br />
          Pulvinar turpis sollicitudin fringilla vulputate turpis. Massa congue ultrices luctus cursus commodo varius tellus enim dignissim. Nisi proin orci sed vestibulum fringilla egestas consectetur tellus cum. Semper natoque condimentum tortor non lacus sed scelerisque eu ut. Facilisis amet vitae nisl lectus libero posuere volutpat. Nisl quis sit nibh amet adipiscing lectus. Senectus ultrices varius elementum commodo elementum nibh arcu. Sed et venenatis imperdiet dui. Tristique adipiscing faucibus nullam sed pellentesque.
          <br /><br />
          Aliquet libero mauris massa sed consequat aliquet. Id arcu feugiat nisl nec tellus. Semper sapien adipiscing quam nibh. Porttitor mauris condimentum consectetur risus consequat lorem urna. At pharetra hendrerit tortor parturient ut. Augue tempor vitae amet facilisis. Eget ac ipsum amet diam volutpat praesent cras. Cras ante ultrices elementum velit blandit natoque integer. Odio vel ac nisi id. Hendrerit consequat nisi augue amet elementum. Magna feugiat ultrices ligula in ultrices elementum gravida. Amet arcu fermentum rhoncus nunc diam. Dolor faucibus risus pellentesque viverra facilisi diam. Enim diam faucibus morbi velit quam purus venenatis mauris. Mauris lacus nibh tincidunt molestie sagittis nam lorem convallis non.
          <br /><br />
          Vitae quis cras feugiat lectus convallis sit dui eget. Vestibulum mauris proin auctor adipiscing maecenas fermentum suscipit ultrices habitasse. Sed imperdiet quis aliquam cras sed risus. Non feugiat fames urna gravida. Nibh est consectetur sem mauris bibendum erat at pellentesque. Pharetra habitasse nunc semper eget nunc aliquam tempus amet condimentum. Velit et duis amet praesent in quam malesuada et.
          <br /><br />
          Elit id in euismod lectus morbi et praesent varius. Tristique purus bibendum nunc iaculis. Ac at nascetur lacinia fringilla morbi sed cras. Lacus turpis aliquam tempus id libero. Nibh quam ac vulputate dignissim hendrerit aliquam. Urna nunc gravida lectus aenean risus id sit ornare. Donec vestibulum turpis et faucibus vulputate dignissim massa. Eros nunc sagittis convallis donec id metus.
          <br /><br />
          Arcu est potenti et ac sed. Vehicula integer bibendum id vel odio. Sit magna arcu ut nulla nullam vitae leo elementum bibendum. Auctor semper sit eget aliquet at vitae bibendum pharetra. Gravida at tincidunt at dapibus elit sit mi aliquam. Viverra turpis consectetur varius dui. Nunc id ornare consectetur adipiscing vel sit sed. Tortor faucibus posuere dui id sollicitudin viverra massa a. Ac varius dictum pellentesque nunc nunc cras turpis mi. Molestie venenatis ullamcorper amet amet hendrerit neque viverra pretium vel. Sed nec quam id molestie curabitur. Amet amet nunc at dolor tortor mauris nisl non cum. Nunc semper aliquam vulputate suspendisse nisl faucibus orci amet eget. Posuere elit enim urna at arcu diam dignissim quam. Nibh habitant urna faucibus aliquet placerat ut id faucibus eu.
          <br /><br />
          Semper velit sem aenean pellentesque sed sed pharetra. Elementum cursus magna leo amet. Vulputate est ipsum montes mauris. Convallis vitae suspendisse tempor sed augue. Ut eget imperdiet pharetra adipiscing a mauris non morbi. Nisi vitae sed non velit aliquam. Scelerisque leo mauris feugiat neque vitae fusce adipiscing magna sed.
          <br /><br />
          Sed dictum pulvinar pharetra velit purus risus. Lobortis quisque maecenas et amet. Massa est amet mauris vel pulvinar vel mus convallis. Faucibus mi lectus semper malesuada in dui turpis ante. Cursus sem tristique at semper sagittis. Eget etiam at tristique feugiat mattis nec.
          <br /><br />
          Phasellus blandit quis sapien eros vehicula adipiscing molestie. Dui malesuada risus purus fringilla lorem habitant erat sed. Vitae velit quam non vitae ac augue. Dis amet mattis purus parturient a. Lobortis iaculis amet velit pharetra pretium arcu massa quam. Pharetra quam tortor magna donec odio congue fermentum. Cras vel amet bibendum enim in diam et eros. Nullam tortor a dolor in est tellus. At mi vitae arcu in mus nibh.
          Elit dolor varius nullam feugiat. Ultrices enim amet ac eleifend vel mauris nulla proin. Lectus sit purus amet cursus dignissim sollicitudin ut. Consequat id accumsan lectus in suspendisse ut. Donec in integer nibh volutpat magnis sed. Ac scelerisque ipsum orci phasellus. Sem blandit adipiscing feugiat at nunc tellus. Aliquam velit turpis diam etiam amet ornare aliquet aliquam. Convallis nibh euismod facilisi nullam ultrices ornare. Vestibulum id id tristique interdum ac quisque a laoreet dictum. Cras ultrices augue fermentum eu tempor nunc id.
          <br /><br />
          Elementum mollis aliquet vestibulum adipiscing. Cursus eget eu diam euismod risus aenean turpis molestie vitae. Neque nullam risus enim blandit velit.
        </text>
      </div>
    </div>
  )
}
