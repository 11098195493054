import { HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
        const err = graphQLErrors.reduce((r, i) => r.concat(i?.extensions?.category), []);
        const path = graphQLErrors.reduce((r, i) => r.concat(i?.path), []);
        console.log("err===>>>>", err);
        if (err.includes('graphql-authorization') && localStorage.getItem('token')) { //Handle Invalid token
            // localStorage.clear();
            // window.location.href = "/login";
        } else if (path.includes('cart') && err.includes('graphql-no-such-entity')) {
            //Handle Cart Inactive
            // localStorage.removeItem('cart_id');
            // window.location.reload();
            return
        }
    }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            Store: "b2b"
        }
    }
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_BACKEND_URL + "graphql",
});

export { errorLink, authLink, httpLink };