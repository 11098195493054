import React, { useEffect, useRef, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateToken } from "@/lib/redux/slices/customer";
import dummyImg from "@/assets/images/user-profile-icon.svg";

const GET_CUSTOMER_DATA = gql`
  query Customer {
    customer {
      firstname
      lastname
      email
      date_of_birth
      gender
      custom_attributes {
        code
        ... on AttributeValue {
          value
        }
        ... on AttributeSelectedOptions {
          selected_options {
            label
            value
          }
        }
      }
    }
  }
`;

const Navbar = ({ show }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [userImg, setUserImg] = useState();
  const { loading, error, data } = useQuery(GET_CUSTOMER_DATA);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // navigate("/");
    }
  }, []);

  const handleLogout = (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("customer");
    localStorage.removeItem("cart_id");
    localStorage.removeItem("two_factor_auth");
    localStorage.removeItem("generateCustomerToken");
    localStorage.removeItem("Social_user");
    localStorage.removeItem("is_two_factor_on");
    localStorage.removeItem("prelogin");

    dispatch(updateToken({ token: "" }));
    window.location.href = "/";
    // navigate('/dev')
  };

  useEffect(() => {
    setCustomerData(data?.customer);
    const img = data?.customer?.custom_attributes?.find(
      (val) => val.code === "profile_pic"
    );
    const fileName = img?.value;
    const url = `${process.env.REACT_APP_BACKEND_URL}media${fileName}`;
    setUserImg(url);

    // if (loading && data === undefined) dispatch(setAuth(false));
  }, [data]);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="bg-gray-300 dark:bg-dark fixed top-0 w-full md:w-[calc(100vw-280px)] z-50">
      {/* <GetData /> */}
      <div className="w-full">
        <div className="flex items-center justify-between bg-white h-[70px] px-4 xl:px-[30px]">
          <div className="flex w-full items-center justify-between">
            <div className="mr-4 flex items-center">
              <div className="group relative mr-4">
                <button
                  ref={trigger}
                  onClick={show}
                  name="hamburger"
                  className="flex md:hidden h-9 w-9 items-center justify-center rounded bg-white bg-opacity-[8%] text-[#74788D]"
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current"
                  >
                    <g clipPath="url(#clip0_1052_7440)">
                      <path d="M19.3854 9.3125H1.88538C1.51038 9.3125 1.16663 9.625 1.16663 10.0312C1.16663 10.4062 1.47913 10.75 1.88538 10.75H19.3854C19.7604 10.75 20.1041 10.4375 20.1041 10.0312C20.1041 9.625 19.7604 9.3125 19.3854 9.3125Z" />
                      <path d="M19.3854 14.625H1.88538C1.51038 14.625 1.16663 14.9375 1.16663 15.3437C1.16663 15.75 1.47913 16.0625 1.88538 16.0625H19.3854C19.7604 16.0625 20.1041 15.75 20.1041 15.3437C20.1041 14.9375 19.7604 14.625 19.3854 14.625Z" />
                      <path d="M1.88538 5.375H19.3854C19.7604 5.375 20.1041 5.0625 20.1041 4.65625C20.1041 4.25 19.7916 3.9375 19.3854 3.9375H1.88538C1.51038 3.9375 1.16663 4.25 1.16663 4.65625C1.16663 5.0625 1.51038 5.375 1.88538 5.375Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1052_7440">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.635376)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <nav
                  ref={dropdown}
                  onFocus={() => setDropdownOpen(true)}
                  onBlur={() => setDropdownOpen(false)}
                  className={`${dropdownOpen === true
                    ? "visible top-full opacity-100 z-10"
                    : "invisible top-[120%] opacity-0"
                    } absolute left-0 mt-2 w-[250px] rounded bg-primary shadow-card-2 duration-200`}
                ></nav>
              </div>
            </div>

            <div className="flex items-center justify-end gap-4">
              <div className="group relative">
                <div className="flex cursor-pointer items-center">
                  <img
                    src={
                      userImg !== "https://store2.fur4.com/media0" &&
                        userImg !== "https://store2.fur4.com/mediaundefined"
                        ? dummyImg
                        : dummyImg
                    }
                    alt="avatar"
                    className="h-8 w-8 rounded-full border-2 border-white border-opacity-20 object-cover object-center"
                  />
                  <span className="pl-[10px] pr-[5px] text-sm font-medium text-[#555B6D]">
                    {customerData?.firstname}
                  </span>
                  <span>
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.63535 9.97501C7.5041 9.97501 7.39473 9.93126 7.28535 9.84376L2.2541 4.90001C2.05723 4.70314 2.05723 4.39689 2.2541 4.20001C2.45098 4.00314 2.75723 4.00314 2.9541 4.20001L7.63535 8.77189L12.3166 4.15626C12.5135 3.95939 12.8197 3.95939 13.0166 4.15626C13.2135 4.35314 13.2135 4.65939 13.0166 4.85626L7.98535 9.80001C7.87598 9.90939 7.7666 9.97501 7.63535 9.97501Z"
                        fill="#555B6D"
                      />
                    </svg>
                  </span>
                </div>
                <div className="invisible absolute right-0 top-[120%] mt-3 w-[200px] space-y-2 rounded z-10 bg-white p-3 opacity-0 shadow-card-2 duration-200 group-hover:visible group-hover:top-full group-hover:opacity-100 dark:bg-dark-2">
                  <DropdownItem name="Logout" onClick={handleLogout} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

const DropdownItem = ({ onClick, name }) => {
  return (
    <button
      onClick={onClick}
      className="block rounded w-full text-start px-2 py-2 text-[13px] text-body-color hover:bg-gray-2 hover:text-primary dark:text-dark-6 dark:hover:bg-dark"
    >
      {name}
    </button>
  );
};
