import { useEffect } from "react";
import NotificationCard from "@/components/Cards/NotificationCard";
import Heading from "@/components/Heading/Heading";
import DonutChart from "@/components/Charts/DonutChart";
import { useDispatch, useSelector } from "react-redux";
import { GET_INVENTORY } from "@/ApiRoute/apiUrls";
import { fetchTopSelling } from "@/redux/apiSlice";
import HeadingCard from "@/components/Cards/HeadingCard";

const STATUS = {
  LOW: 'Low',
  OPTIMUM: "Optimum",
  VERY_HIGH: 'Very High',
  QTY_ZERO: 0,
  QTY_100: 100,
  QTY_HIGH: 5000
}

const NotificationAlerts = () => {
  const dispatch = useDispatch();
  const productStock = useSelector((state) => state.myApis.topSellingRecord);

  console.log("topSellingData", productStock?.data?.items);

  useEffect(() => {
    dispatch(fetchTopSelling(GET_INVENTORY));
  }, []);

  const getLevels = (value) => {
    return value < STATUS.QTY_100
      ? STATUS.LOW
      : value < STATUS.QTY_HIGH
        ? STATUS.OPTIMUM
        : STATUS.VERY_HIGH;
  };

  const getColor = (value) => {
    return value < STATUS.QTY_100
      ? "text-[red]"
      : value < STATUS.QTY_HIGH
        ? "text-[blue]"
        : "text-[green]";
  };

  return (
    <div className="text-primary">
      <h1 className="text-4xl font-DIN-Condensed font-bold mb-5">
        Notifications and Alerts
      </h1>
      <div className=" space-y-5">
        <div className=" grid grid-cols-1 xl:grid-cols-2 w-full gap-5 h-[440px] mb-36 ">
          {/* Stock level */}
          <HeadingCard
            cardTitle={"Stock level"}
            // height={"h-[440px]"}
            extraContent={"Showing Low Stocks Levels First"}
            extraContentStyle={"text-[16px] font-sf-pro-display"}
          >
            <div className="custom-scrollbar h-[440px] overflow-y-auto ">
              {productStock?.data?.items?.map((val, index) => (
                <NotificationCard
                  stockLevel={getLevels(Number(val?.quantity))}
                  sku={val?.sku}
                  className={getColor(Number(val?.quantity))}
                  stock={val?.quantity}
                />
              ))}
            </div>
          </HeadingCard>

          {/* Ricket Volumes */}
          <HeadingCard cardTitle={"Ticket Volumes"}>
            <div className="flex flex-row   justify-between   h-[440px] ">
              <div className=" flex flex-col justify-center  text-[20px] text-[#434345] border-r border-r-[#EBEBEB] ">
                <div className="flex flex-col  items-center border-b border-b-[#EBEBEB] py-10 ">
                  <p className="text-center">Unsolved Tickets</p>
                  <h1 className="text-[32px]">106</h1>
                </div>
                <div className="flex flex-col  items-center border-b border-b-[#EBEBEB] py-12">
                  <p className="text-center"> Tickets Open </p>
                  <h1 className="text-[32px]">45</h1>
                </div>
                <div className="flex flex-col  items-center py-10">
                  <p className="text-center">Tickets unAssigned</p>
                  <h1 className="text-[32px]">61</h1>
                </div>
              </div>
              <div className=" flex flex-col w-[60%] p-5">
                <div className="flex justify-center items-center">
                  <DonutChart />
                </div>
                <div className=" flex flex-col  lg:flex-row justify-between  relative bottom-[-35%]">
                  <div className="text-[20px] font-sf-pro-display text-[#434345]">
                    <p>Volume</p>
                    <p className="text-[#F46A6A] ">High Ticket Volume</p>
                  </div>
                  <div>
                    <button className="px-8 py-1 truncate border rounded-[30px] bg-[#F46A6A] text-white text-[14px]">
                      Alert User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </HeadingCard>
        </div>

        <HeadingCard cardTitle={"Campaign Performance Dips"}>
          <div className="flex flex-row justify-center items-center h-[200px] ">
            <p className="text-[20px] text-[#00C0F3] text-center ">
              Need some ideas as to what to put here
            </p>
          </div>
        </HeadingCard>
      </div>
    </div>
  );
};
export default NotificationAlerts;
