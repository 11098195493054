import {gql} from "@apollo/client";

//Mutations
export const CREATE_EMPTY_CART = gql`
    mutation CreateEmptyCart {
        createEmptyCart(input: { cart_id: null })
    }
`

export const ADD_PRODUCTS_TO_CART = gql`
    mutation AddProductsToCart($cartId:String!, $quantity:Float!, $sku:String!) {
        addProductsToCart(
            cartId: $cartId,
            cartItems: { quantity: $quantity, sku: $sku }
        ) {
            cart {
                id
                items {
                    id
                    product {
                        name
                        sku
                        price {
                            regularPrice {
                                amount {
                                    currency
                                    value
                                }
                            }
                        }
                        color
                    }
                    quantity
                }
            }
        }
    }
`
export const GET_CART_ID = gql`
  query customerCart{
    customerCart {
      id
      items {
        id
        product {
          name
          sku
        }
        quantity
      }
    }
  }
`
export const ADD_TIER_PRODUCT_TO_CART=gql`
mutation addBundleProductsToCart($cartId:String!,$sku:String!,$productId:String!,$optionId:Int!){
  addBundleProductsToCart(
    input: {
      cart_id: $cartId
      cart_items: [
      {
        data: {
          sku: $sku
          quantity: 1
        }
        bundle_options: [
          {
            id: $optionId
            quantity: 1
           value: [$productId]
          }
        ]
      },
    ]
  }) {
    cart {
      itemsV2 {
        items {
          uid
          quantity
          product {
            sku
          }
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
        }
        total_count
        page_info {
          page_size
          current_page
          total_pages
        }          
      }
    }
  }
}

`
export const REMOVE_ITEM_FROM_CART = gql`
    mutation RemoveItemFromCart($cart_id:String!,$cart_item_id:Int!) {
        removeItemFromCart(input: { cart_id: $cart_id, cart_item_id: $cart_item_id }) {
            cart {
                total_quantity
            }
        }
    }
`

export const UPDATE_CART_ITEMS = gql`
    mutation UpdateCartItems($cart_id:String!,$cart_item_id:Int!,$quantity:Float!) {
        updateCartItems(
            input: { cart_id: $cart_id, cart_items: { cart_item_id: $cart_item_id, quantity: $quantity } }
        ) {
            cart {
                id
            }
        }
    }
`

export const MERGE_CART = gql`
    mutation MergeCarts($source_cart_id:String!) {
        mergeCarts(source_cart_id: $source_cart_id) {
            id
            items {
                product {
                    name
                    sku
                }
            }
        }
    }`


//Queries


export const CUSTOMER_CART = gql`
    query CustomerCart {
        customerCart {
            id
            total_quantity
            items {
                id
                quantity
                product {
                    name
                    sku
                    image {
                        disabled
                        label
                        position
                        url
                    }
                    price {
                        regularPrice {
                            amount {
                                currency
                                value
                            }
                        }
                    }
                    color
                }
                quantity
            }
            prices {
                grand_total {
                    value
                }
            }
        }
}
`


export const CART = gql`
query Cart($cart_id: String!) {
  cart(cart_id: $cart_id) {
    id
    total_quantity
    items {
      id
      product {
        name
        sku
        image {
          disabled
          label
          position
          url
        }
        price {
          regularPrice {
            amount {
              currency
              value
            }
          }
        }
      }
      quantity
    }
    prices {
      grand_total {
        value
        currency
      }
    }
    email
    billing_address {
      city
      country {
        code
        label
      }
      firstname
      lastname
      postcode
      region {
        code
        label
      }
      street
      telephone
    }
    shipping_addresses {
      firstname
      lastname
      street
      city
      region {
        code
        label
      }
      country {
        code
        label
      }
      telephone
      available_shipping_methods {
        amount {
          currency
          value
        }
        available
        carrier_code
        carrier_title
        error_message
        method_code
        method_title
        price_excl_tax {
          value
          currency
        }
        price_incl_tax {
          value
          currency
        }
      }
      selected_shipping_method {
        amount {
          value
          currency
        }
        carrier_code
        carrier_title
        method_code
        method_title
      }
    }
    itemsV2 {
      total_count
      items {
        id
        uid
        product {
          name
          sku
        }
        quantity
        prices {
          total_item_discount {
            value
          }
          price {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
         ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
    }
    available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
      title
    }
    applied_coupons {
      code
    }
    prices {
      grand_total {
        value
        currency
      }
       discounts {
        amount {
          value
        }
        applied_to  
        label
      }
    }
  }
}
`;

export const CREATE_CUSTOMER_CART_RAW = gql`
  {
    customerCart {
      id
    }
  }
`;


export const CREATE_GUEST_CART_RAW = gql`
mutation {
    createGuestCart {
          cart {
              id
          }
    }
}
`;