import React from "react";

const HeadingCard = ({ children, src, cardTitle, className, height, extraContent, extraContentStyle }) => {
  return (
    <div className={className ? className : ""}>
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center space-x-3 mb-2">
          {src && (
            <img src={src} alt="Quick Books Logo" className="h-fit w-fit" />
          )}
          <h1 className="text-[28px] text-primary font-DIN-Condensed font-bold">
            {cardTitle}
          </h1>
        </div>
        {extraContent && <span className={extraContentStyle}>
          {extraContent}</span>}
      </div>
      <div className={`drop-shadow-md rounded-md bg-white p-5 space-y-5 ${height}  `}>
        {children}
      </div>
    </div>
  );
};

export default HeadingCard;
