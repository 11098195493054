import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "@/lib/apollo/client";
import { CART, CREATE_GUEST_CART_RAW, CREATE_CUSTOMER_CART_RAW, MERGE_CART, CUSTOMER_CART } from "@/lib/apollo/queryes/shoppingCart";

const fetchCart = createAsyncThunk("customer/fetchCart", async (args, { dispatch }) => {
    try {
        const { data } = await client.query({
            query: CART,
            variables: { cart_id: localStorage.getItem('cart_id') },
            fetchPolicy: 'no-cache'
        });
        return data.cart;
    } catch (error) {
        throw error;
    }
});
const customerStatus = createAsyncThunk("customer/status", async (args, { dispatch }) => {
    try {
        //To check if customer is logged in or not
        const res = await client.query({
            query: CUSTOMER_CART,
            fetchPolicy: 'no-cache'
        });
        localStorage.setItem('cart_id', res.data.customerCart.id)
        dispatch(fetchCart())
        return res?.data
    } catch (error) {
        localStorage.clear("token");
        if (!localStorage.getItem("cart_id")) {
            localStorage.clear("cart_id")
            dispatch(initCart())
        } else {
            dispatch(fetchCart())
        }
    }
});
const initCart = createAsyncThunk("customer/initCart", async (args, { dispatch }) => {
    try {
        const type = localStorage.getItem('token') ? 'customer' : 'guest';
        if (type === "guest") {
            const res = await client.mutate({
                mutation: CREATE_GUEST_CART_RAW,
                fetchPolicy: 'no-cache'
            });
            return res.data.createGuestCart.cart.id;
        } else {
            const res = await client.query({
                query: CREATE_CUSTOMER_CART_RAW,
                fetchPolicy: 'no-cache'
            });
            return res.data.customerCart.id;
        }
    } catch (error) {
        console.log(error)
    }
});

const mergeCart = createAsyncThunk("customer/mergeCart", async (args, { dispatch }) => {
    try {
        const { mergeCarts } = await client.mutate({
            mutation: MERGE_CART,
            fetchPolicy: 'no-cache',
            variables: { source_cart_id: localStorage.getItem("cart_id") }
        });
        return mergeCarts.id;
    } catch (error) {
        throw error;
    }
});

export const customerSlice = createSlice({
    name: "customer",
    initialState: {
        token: localStorage.getItem('token') || null,
        cart_id: localStorage.getItem('cart_id') || null,
        cart_open: false,
        cart: {},
        fetching_cart: false, //This is created to check if fetching cart
        initiating_cart: false, //This is created to check if cart fetching and creating empty cart already in progress or not
        additionalStatus: 0, // Additional Task status index
    },
    reducers: {
        updateCartId: (state, action) => {
            state.cart_id = action.payload.cart_id;
            localStorage.setItem('cart_id', state.cart_id)
        },
        updateToken: (state, action) => {
            state.token = action.payload.token;
            localStorage.setItem('token', state.token)
        },
        updateInitiatingCart: (state, action) => {
            state.initiating_cart = action.payload;
        },
        updateFetchingCart: (state, action) => {
            state.fetching_cart = action.payload;
        },
        getCustomerData(state) {
            const token = localStorage.getItem('token')
            const cart_id = localStorage.getItem('cart_id')
            state.token = token
            state.cart_id = cart_id
        },
        cartOpen: (state, action) => {
            state.cart_open = action.payload.cart_open;
        },
        setAdditionalStatus: (state, action) => {
            state.additionalStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.initiating_cart = false;
                if (action.payload) {
                    state.cart = action.payload;
                    state.fetching_cart = false;
                    localStorage.setItem('cart_id', action.payload.id)
                }
            })
            .addCase(initCart.fulfilled, (state, action) => {
                state.initiating_cart = false;
                if (action.payload) {
                    state.cart_id = action.payload;
                    localStorage.setItem('cart_id', state.cart_id)
                }
            }).addCase(mergeCart.fulfilled, (state, action) => {
                if (action.payload) {
                    state.cart_id = action.payload;
                    localStorage.setItem('cart_id', state.cart_id);
                }
            });
    }
});

export const { updateCartId, updateToken, getCustomerData, cartOpen, updateInitiatingCart, updateFetchingCart, setAdditionalStatus } = customerSlice.actions

export default customerSlice.reducer;

export { fetchCart, initCart, mergeCart, customerStatus };