import React, { useState } from 'react';

const RadioButton = ({ options, onOptionChange, className }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    onOptionChange(value);
  };

  return (
    <div className="flex flex-col items-start py-4">
      <form>
        <fieldset>
          <div className="flex space-x-4">
            {options.map((option, index) => (
              <label key={index} className={`flex items-center space-x-2 ${className}`}>
                <input
                  type="radio"
                  name="radioGroup"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={handleOptionChange}
                  className="font-semibold cursor-pointer text-deepskyblue-100"
                />
                <span className="text-primary">{option.label}</span>
              </label>
            ))}
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default RadioButton;
