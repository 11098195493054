import React from "react";
import ReactPaginate from "react-paginate";
import leftArrow from "@/assets/images/leftarrow.png";
import rightArrow from "@/assets/images/rightarrowpage.png";

export default function PaginationComp({
  handlePageClick,
  totalCount,
  perPageCount,
}) {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<img src={rightArrow} alt="Next" className="h-fit w-fit" />}
        containerClassName="w-full bg-red-200"
        className=" flex justify-end items-center space-x-2 text-lg font-medium rounded-full w-full text-white"
        pageClassName="h-9 w-9 text-lg flex justify-center items-center font-medium bg-deepskyblue-100 rounded-full text-white text-center mt-1 "
        breakLinkClassName="h-9 w-9 text-lg font-medium rounded-full text-black text-center mt-1 "
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        pageCount={Math.ceil(totalCount / perPageCount)}
        previousLabel={<img src={leftArrow} alt="Next" className="h-fit w-fit" />}
        renderOnZeroPageCount={null}
        activeClassName="bg-white !text-black border-[1px] border-deepskyblue-100"
      />
    </div>
  );
}
