import React from "react";
import editIcon from "@/assets/images/editIcon.png";
import discountIcon from "@/assets/images/discountIcon.png";
import settingIcon from "@/assets/images/settingIcon.png";

const DealerManagementCard = ({ name, src }) => {
  return (
    <div className="flex flex-col justify-center items-center font-sf-pro-display p-5 space-y-2">
      <img src={src} alt="Dealer " className="h-[150px] w-[150px] rounded-full" />
      <div className="text-lg text-primary font-bold">{name}</div>
      <div className="flex space-x-2 cursor-pointer">
        <img src={editIcon} alt="" className="h-fit w-fit" />
        <p className="text-xs text-primary">Edit Profile</p>
      </div>
      <div className="flex space-x-2 cursor-pointer">
        <img src={discountIcon} alt="" className="h-fit w-fit" />
        <p className="text-xs text-primary">Set Discounts</p>
      </div>
      <div className="flex space-x-2 cursor-pointer">
        <img src={settingIcon} alt="" className="h-fit w-fit" />
        <p className="text-xs text-primary">Manage Permisions</p>
      </div>
    </div>
  );
};

export default DealerManagementCard;
